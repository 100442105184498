.privacy-policy-main {
    .pp-heading {
        font-size: 20px !important;
        margin-top: 2rem !important;
    }
    .document-date {
        margin-top: 2rem;
    }
    .sub-section {
        article {
            li {
                list-style: disc;
            }
        }
    }
}
