* {
    font-family: 'Manrope', sans-serif !important;
}
/* @media (width < 600px) {
    * {
        overflow-x: hidden;
    }
} */
.ant-avatar {
    background-color: rgb(144, 145, 145);
    border: none !important;
    box-shadow: -1px 0px 2px rgb(0, 0, 0);
}
.ant-modal-header {
    position: sticky;
    top: 0;
    z-index: 99;
    background-color: transparent !important;
}
@media (min-width: 200px) and (max-width: 480px) {
    /* * {
        overflow-x: hidden;
    } */
    .ant-layout-header {
        height: 70px !important;
    }
}
@media (min-width: 480px) and (max-width: 768px) {
    /* * {
        overflow-x: hidden;
    } */
    .ant-layout-header {
        height: 70px !important;
    }
}
/* =====================description===================== */
.content {
    font-family: 'Manrope';
    font-weight: 400;
    font-size: 14px;
    color: #a6a6a6;
    text-wrap: wrap;
}
.hide {
    display: none;
}
#check {
    display: none;
}
.see-less {
    display: none;
}
label {
    color: #3888ff;
}
#check:checked ~ .hide {
    display: block;
}
#check:checked ~ .see-more {
    display: none;
}
#check:checked ~ .see-less {
    display: block;
}
/* =======================button-and-social================== */
.button-and-social-section {
    width: 100%;
    padding: 0 0 0 30px;
}

/* =======================Modified Grid for Mobile ====================== */
.event-details-grid {
    display: flex; /* Changed from grid to flex */
    flex-direction: column; /* Default column layout */
    gap: 20px; /* Space between sections */
}

/* Priority sections for mobile: Scan Me and Buy Ticket */
.priority-container {
    order: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Scan Me Section */
.scan-me-section {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center-align content on mobile */
    background: #242631;
    border-radius: 8px;
    padding: 15px;
    color: #ffffff;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Buy Ticket Section */
.buy-ticket-section {
    display: flex;
    justify-content: center;
    padding: 15px;
    background: #ff384e;
    border-radius: 8px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.buy-ticket-section:hover {
    background: #e03140; /* Darker red for hover effect */
}

/* Description and other sections */
.description-container {
    order: 2;
    padding: 15px;
    background: #333333;
    border-radius: 8px;
    color: #f0f0f0;
}

/* ======================= Responsive Reordering ====================== */
@media (max-width: 768px) {
    .event-details-grid {
        display: flex;
        flex-direction: column; /* Stack sections vertically */
        gap: 20px;
    }

    .priority-container {
        order: 1; /* Show Scan Me and Buy Ticket sections first */
    }

    .description-container {
        order: 2; /* Show Event Description below */
    }

    .scan-me-section, .buy-ticket-section {
        width: 100%; /* Full width on mobile */
    }
}

@media (min-width: 769px) {
    .event-details-grid {
        display: grid;
        grid-template-columns: 2fr 1fr; /* Desktop layout: 2/3 content, 1/3 sidebar */
        gap: 20px;
    }

    .priority-container {
        order: 2; /* Keep Scan Me and Buy Ticket as the sidebar */
    }

    .description-container {
        order: 1; /* Keep Event Description on the left */
    }
}

/* Ensure right-alignment for Buy Ticket button on desktop */
.d-md-flex {
    display: flex;
}

.justify-content-end {
    justify-content: flex-end;
}

/* Optionally center the button on mobile */
@media (max-width: 767px) {
    .d-md-none {
        text-align: center;
    }
}

/* Announcement Section */
.announcement-container {
    background-color: #333333;
    border: 1px solid #666666;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 20px;
    color: #f0f0f0;
}

.announcement-title {
    color: #ffd700;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.view-more-link a {
    color: #00ccff;
}

.details span, .content.shown {
    color: #f0f0f0;
}
.description-text {
    color: #e0e0e0;
}

.terms-condition {
    color: #00ccff;
}

.faq-section {
    color: #ffd700;
}

/* Event detail-specific font override */
.event-detail, 
.event-detail * {
    font-family: 'Inter', sans-serif !important;
}

.content, .details span, .content.shown, .description-text, .terms-condition, .faq-section {
    color: #f0f0f0;
}

.content {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #a6a6a6;
}
