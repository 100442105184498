.disclaimer-main {
    .d-heading {
        font-size: 20px !important;
        margin-top: 2rem !important;
    }
    .sub-section {
        .heading {
            font-size: 18px;
        }
    }
}
